import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../apiConfig';
import { useTranslation } from 'react-i18next';
import RecipeCard from '../recipes/RecipeCard';
import './SearchPage.css';
import {Recipe} from "../../types"; // Import the CSS file

const SearchPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const query = queryParams.get('q') || '';
  const courseType = queryParams.get('course_type') || '';
  const cuisine = queryParams.get('cuisine') || '';
  const dietaryPreferences = queryParams.get('dietary_preferences') || '';
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [loading, setLoading] = useState(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [hasMore, setHasMore] = useState(false);
  const { t } = useTranslation();

  const fetchSearchResults = async (append: boolean = false) => {
    try {
      const url = new URL(`${API_BASE_URL}/recipes/search`);
      url.searchParams.append('q', query);
      if (lastEvaluatedKey) url.searchParams.append('lastEvaluatedKey', lastEvaluatedKey);
      if (courseType) url.searchParams.append('course_type', courseType);
      if (cuisine) url.searchParams.append('cuisine', cuisine);
      if (dietaryPreferences) url.searchParams.append('dietary_preferences', dietaryPreferences);

      const response = await fetch(url.toString());

      if (!response.ok) {
        throw new Error(`Error fetching search results: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      if (data && data.items.length > 0) {
        setRecipes((prevRecipes) => (append ? [...prevRecipes, ...data.items] : data.items));
        setLastEvaluatedKey(data.lastEvaluatedKey || undefined);
        setHasMore(data.lastEvaluatedKey !== null && data.lastEvaluatedKey !== undefined && data.lastEvaluatedKey !== '');
      } else {
        setHasMore(false);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setLoading(false);
    }
  };

  const handleCreateRecipe = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/recipes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title: query }),
      });

      if (response.ok) {
        const data = await response.json();
        navigate(`/recipes/${data.id}`, { state: { recipe: data } });
      } else {
        console.error('Error creating recipe:', response.statusText);
      }
    } catch (error) {
      console.error('Error during recipe creation:', error);
    }
  };

  const loadMoreResults = () => {
    if (hasMore) {
      fetchSearchResults(true); // Append new results to existing recipes
    }
  };

  useEffect(() => {
    if (!location.state?.recipes) {
      fetchSearchResults();
    } else {
      setRecipes(location.state.recipes);
      setLoading(false);
    }
  }, [query]);

  if (loading) return <div className="loading-spinner">{t('loading')}</div>;

  if (recipes.length === 0) {
    return (
      <div className="search-page">
        <p className="no-results">{t('noRecipesFound', { query })}</p>
        <button className="create-recipe-button" onClick={handleCreateRecipe}>
          {t('generateRecipe')}
        </button>
      </div>
    );
  }

  return (
    <div className="search-page">
      <h1 className="search-page-title">{t('searchResultsFor', { query })}</h1>
      <div className="recipe-grid">
        {recipes.map((recipe) => (
          <RecipeCard key={recipe.id} recipe={recipe} />
        ))}
      </div>
      {hasMore && (
        <button className="load-more-button" onClick={loadMoreResults} disabled={loading}>
          {t('loadMore')}
        </button>
      )}
    </div>
  );
};

export default SearchPage;
