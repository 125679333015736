import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import './App.css';
import AppRoutes from "./Routes";
import Footer from "./components/page-structure/Footer";
import Header from "./components/page-structure/Header";
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {AuthUIProvider} from "./components/auth/AuthUIContext";
import AuthenticatorModal from "./components/auth/AuthenticatorModal";
import ReactGA from "react-ga4";

// Replace with your GA Measurement ID
const GA_MEASUREMENT_ID = "G-LB3HXE8YCZ";

ReactGA.initialize(GA_MEASUREMENT_ID);

const App: React.FC = () => {
  const location = useLocation();
  const {i18n} = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lang = params.get('lang');
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.dir(i18n.language);

    if (lang && (i18n.language?.split('-')[0]) !== lang) {
      i18n.changeLanguage(lang); // Change language globally
    }

  }, [location, i18n, i18n.language]);

  return (

    <React.StrictMode>

      <Authenticator.Provider>
        <AuthUIProvider>
          <div className="App">
            <AuthenticatorModal/> {/* Include the AuthenticatorModal here */}
            <Header/>
            <AppRoutes/>
            <Footer/>
          </div>
        </AuthUIProvider>
      </Authenticator.Provider>

    </React.StrictMode>
  );
}

export default App;