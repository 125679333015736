import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import {useTranslation} from "react-i18next";

// Helper function to clean dynamic paths
const normalizePath = (pathname: string): string => {
  const uuidRegex = /\/recipes\/[0-9a-fA-F-]{36}/;
  if (uuidRegex.test(pathname)) {
    pathname = pathname.replace(uuidRegex, "/recipes/:id");
  }
  return pathname; // Leave other paths unchanged
};

// Component to track page views
const TrackPageView: React.FC = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const cleanPath = normalizePath(location.pathname);
    // Send the normalized path to Google Analytics
    ReactGA.send({
      hitType: 'pageview',
      page: cleanPath,
      title: document.title, // Optional: Track page title
      customParams: { locale: i18n.language }, // Add locale/language
    })
  }, [location, i18n.language]);

  return null;
};

export default TrackPageView;
