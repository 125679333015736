import React, {useEffect, useState} from 'react';
import {Button, List, ListItem, ListItemButton, ListItemText, Paper, TextField, Typography,} from '@mui/material';

import './SearchBar.css'
import filterConfig from './filterConfig';
import SelectionGroup from "./SelectionGroup";
import {useNavigate} from 'react-router-dom';
import {API_BASE_URL} from "../../apiConfig";
import {useTranslation} from "react-i18next";
import {SearchResponse} from "../../types";

export interface SearchParams {
  query: string;
  filters: { [key: string]: string[] };
}



const SearchBar: React.FC = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(); //todo use actual translation
  const lang = (i18n.language?.split('-')[0]) || 'en';

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length >= 3) {
        try {
          // Add 'suggestions=true' to the query string
          const response = await fetch(`${API_BASE_URL}/recipes/search?q=${encodeURIComponent(query)}&suggestions=true`);

          if (!response.ok) {
            throw new Error(`Error fetching suggestions: ${response.status} ${response.statusText}`);
          }

          const data: SearchResponse = await response.json();

          // Extract and set the items (suggestions) from the response
          const searchKeys = (data.items || []).map(item => item.SearchKey);
          setSuggestions(searchKeys);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
          setSuggestions([]); // Reset suggestions on error
        }
      } else {
        setSuggestions([]); // Clear suggestions if query is too short
      }
    };


    const debounceFetch = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(debounceFetch);
  }, [query]);

  const handleSearch = async () => {
    if (!query) return;

    // Prepare query parameters
    const searchParams = new URLSearchParams();
    searchParams.append('q', query);
    searchParams.append('lang', lang);

    Object.entries(filters).forEach(([group, selectedOptions]) => {
      if (selectedOptions.length > 0) {
        searchParams.append(group.toLowerCase().replace(/\s+/g, '_'), selectedOptions.join(','));
      }
    });

    try {
      // Send search request
      const response = await fetch(`${API_BASE_URL}/recipes/search?${searchParams.toString()}`);
      const data = await response.json();

      // Navigate to search results page with query params and results
      navigate(`/recipes/search?${searchParams.toString()}`, {state: {results: data, query}});
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const updateFilters = (groupName: string, selectedOptions: string[]) => {
    setFilters((prev) => ({
      ...prev,
      [groupName]: selectedOptions,
    }));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="background">
      <div className="overlay">
        <Paper className="root" elevation={3}>
          <Typography variant="h4" gutterBottom>
            Find the Perfect Recipe
          </Typography>
          <TextField
            label="Search recipes..."
            variant="outlined"
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          {suggestions.length > 0 && (
            <List className="suggestionsList">
              {suggestions.map((suggestion) => (
                <ListItem key={suggestion} disablePadding>
                  <ListItemButton onClick={() => setQuery(suggestion)}>
                    <ListItemText primary={suggestion}/>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
          <Typography variant="h6" gutterBottom style={{marginTop: '20px'}}>
          </Typography>
          {Object.entries(filterConfig).map(([groupName, config]) => (
            <SelectionGroup
              key={groupName}
              groupName={groupName}
              options={config.options}
              isSingleSelection={config.isSingleSelection}
              onSelectionChange={(selectedOptions) => updateFilters(groupName, selectedOptions)}
            />
          ))}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSearch}
            style={{marginTop: '20px'}}
          >
            Search
          </Button>
        </Paper>
      </div>
    </div>
  );
};

export default SearchBar;
